import { SATrackingEvents } from '../../constants/tracking'

class SA_FB {
	static trackEvent(event) {
        if (!window.fbq) return

		// Convert event name from snake_case to PascalCase
		const pascalCaseEvent = event.split('_')
			.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
			.join('');

		switch (event) {
			case SATrackingEvents.LEAD:
				// FB has a standard lead event, so we should use it
				window.fbq('track', 'Lead')
				break
			case SATrackingEvents.QUESTIONS_STARTED:
			case SATrackingEvents.QUESTIONS_FINISHED:
			case SATrackingEvents.LEAD_DETAILS_UPDATED:
				window.fbq('trackCustom', pascalCaseEvent)
				break
			default:
				break
		}
	}
}

export default SA_FB
