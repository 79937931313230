export const initSAInline = (_this, { element, autoHeight}) => {
	if (!element) {
		console.error('ScoreAppWidget: Element is required')
		return
	}


	element.appendChild(_this.iframe)


	if (autoHeight){
		const  handleScroll = () => {
			if (!_this.iframe) return;

			_this.iframe.contentWindow.postMessage({
				iframe: {
					rect: _this.iframe.getBoundingClientRect(),
					innerHeight: window.innerHeight,
					innerWidth: window.innerWidth,
				},
			}, '*');
		}
		const pageClasses = {
			question: 'sa--page-question',
			landing: 'sa--page-landing',
			result: 'sa--page-result',
		}

		const handleIframeResize = (event) => {
			try {
				if (!event.data.scoreapp || !_this.iframe) {
					return;
				}

				const type = event.data.scoreapp && event.data.scoreapp.type

				_this.iframe.style.height = `${event.data.scoreapp.height + 10}px`;
				Object.values(pageClasses).forEach((className) => {
					_this.iframe.classList.remove(className)
				})
				const className = pageClasses[type]

				if (className) {
					_this.iframe.classList.add(className)
				}

				handleScroll();

			} catch (e) {}
		}

		window.addEventListener('message', handleIframeResize);
		window.addEventListener('resize', handleScroll);
		window.addEventListener('scroll', handleScroll, true);

		_this.iframe.addEventListener('load', () => {
			_this.iframe.contentWindow.postMessage({
				scoreapp: {
					autoHeight: true,
				}
			}, '*');

			_this.iframe.style.height = '100vh';
			handleScroll();
		});

		element.style.height = 'auto';
	}
}
