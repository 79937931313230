const hexToRgb = (color) => {
	// eslint-disable-next-line no-param-reassign
	color = color.substring(1);
	const re = new RegExp('.{1,'.concat(color.length >= 6 ? '2' : '1', '}'), 'g');
	let colors = color.match(re);

	if (colors && colors[0].length === 1) {
		colors = colors.map(n => {
			return n + n;
		});
	}

	return colors
		? 'rgb'.concat(colors.length === 4 ? 'a' : '', '(').concat(
			colors
				.map((n, index) => {
					return index < 3 ? parseInt(n, 16) : Math.round((parseInt(n, 16) / 255) * 1000) / 1000;
				})
				.join(', '),
			')',
		)
		: '';
};

const decomposeColor = (color) => {
	// Idempotent
	if (typeof color === 'object') {
		return color;
	}

	if (color.charAt(0) === '#') {
		return decomposeColor(hexToRgb(color));
	}

	const marker = color.indexOf('(');
	const type = color.substring(0, marker);

	if (['rgb', 'rgba', 'hsl', 'hsla'].indexOf(type) === -1) {
		return {
			type: 'rgb',
			values: [0, 0, 0],
		};
	}

	const values = color.substring(marker + 1, color.length - 1).split(',');
	return {
		type,
		values: values.map(value => {
			return parseFloat(value);
		}),
	};
};

function recomposeColor(color) {
	// eslint-disable-next-line prefer-destructuring
	let values = color.values;
	const { type } = color;

	if (type.indexOf('rgb') !== -1) {
		// Only convert the first 3 values to int (i.e. not alpha)
		values = values.map((n, i) => {
			// eslint-disable-next-line no-nested-ternary
			return i < 3 ? (typeof n === 'string' ? parseInt(n, 10) : n) : n;
		});
	} else if (type.indexOf('hsl') !== -1) {
		values[1] = ''.concat(values[1], '%');
		values[2] = ''.concat(values[2], '%');
	}

	return ''.concat(type, '(').concat(values.join(', '), ')');
}

const hslToRgb = (color) => {
	const decomposedColor = decomposeColor(color);
	const { values } = decomposedColor;
	const h = values[0];
	const s = values[1] / 100;
	const l = values[2] / 100;
	const a = s * Math.min(l, 1 - l);

	const f = function f(n) {
		// eslint-disable-next-line prefer-rest-params
		const k = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (n + h / 30) % 12;
		return l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
	};

	let type = 'rgb';
	const rgb = [Math.round(f(0) * 255), Math.round(f(8) * 255), Math.round(f(4) * 255)];

	if (decomposedColor.type === 'hsla') {
		type = 'rgba';
		rgb.push(values[3]);
	}

	return recomposeColor({
		type,
		values: rgb,
	});
};

const getLuminance = (color) => {
	const decomposedColor = decomposeColor(color);
	let rgb = decomposedColor.type === 'hsl' ? decomposeColor(hslToRgb(color)).values : decomposedColor.values;
	rgb = rgb.map(val => {
		// eslint-disable-next-line no-param-reassign
		val /= 255; // normalized

		// eslint-disable-next-line no-restricted-properties
		return val <= 0.03928 ? val / 12.92 : Math.pow((val + 0.055) / 1.055, 2.4);
	}); // Truncate at 3 digits

	return Number((0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]).toFixed(3));
};

export const isDarkColor = color => getLuminance(color) < 0.5

export const calcContrastColor = (background, foreground = '#FFFFFF') => {
	if (!foreground || !background) {
		return foreground
	}

	try {
		const darkForeground = isDarkColor(foreground)
		const darkBackground = isDarkColor(background)

		if (darkForeground !== darkBackground) {
			return foreground
		}

		return darkBackground ? '#FFFFFF' : '#323A3F'
	} catch (e) {
		return foreground
	}
}
