import SA_GTM from './utils/tracking/gtm';
import SA_FB from './utils/tracking/fb';
import SA_GA from './utils/tracking/ga';

class SATracking {

	/**
	 * Tracks an event across GA, GTM, and FB.
	 * @param {string} event - The event name to track.
	 * @param {object} data - The data associated with the event.
	 */
	static track(event, data) {

		SA_FB.trackEvent(event);
		SA_GA.trackEvent(event, data);
		SA_GTM.trackEvent(event, data);

	}
}

export { SATracking };
