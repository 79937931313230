import { initSAPopup } from './popup'
import { initSAFull } from "./full";
import { initSAInline } from "./inline";
import { initSASlider } from "./slider";
import { initSAChat } from "./chat";
import { SATracking } from "./tracking";

(function () {
	if (window.ScoreAppWidget) return

	const SESSION_ID_KEY = 'SASID'

	function ScoreAppWidget({ scorecardUrl }){
		this.scorecardUrl = scorecardUrl
		this.sessionId = localStorage.getItem(SESSION_ID_KEY)
		this.generateIframe()

	}

	ScoreAppWidget.prototype.generateIframe = function(){
		const url = new URL(this.scorecardUrl);
		if (this.sessionId) {
			url.searchParams.set(SESSION_ID_KEY, this.sessionId);
		}
		const parentParams = new URLSearchParams(window.location.search);
		for (let pair of parentParams.entries()) {
			url.searchParams.append(pair[0], pair[1]);
		}
		this.iframe = document.createElement('iframe');
		this.iframe.src = url.toString();
		this.iframe.style.cssText = 'width:100%;height:100%;border:none;';
		this.iframe.setAttribute('sandbox', 'allow-forms allow-popups allow-scripts allow-top-navigation allow-same-origin' );
	}


	const createFunction = fn => {
		return function (...args){
			fn(this, ...args)
		}
	}


	ScoreAppWidget.prototype.initInline = createFunction(initSAInline);
	ScoreAppWidget.prototype.initPopup = createFunction(initSAPopup);
	ScoreAppWidget.prototype.initFull = createFunction(initSAFull);
	ScoreAppWidget.prototype.initSlider = createFunction(initSASlider);
	ScoreAppWidget.prototype.initChat = createFunction(initSAChat);

	ScoreAppWidget.createFromElement = function (element) {
		if (!element) {
			console.error('ScoreAppWidget: Embed block not found. Make sure you copied all code');
			return;
		}
		const { saView, saUrl } = element.dataset;

		element.removeAttribute('data-sa-url');
		element.removeAttribute('data-sa-view');

		if (!saUrl || !saView ){
			console.error('ScoreAppWidget: Missing required properties. Make sure you copied all code');
			return
		}

		const instance = new this({scorecardUrl: saUrl })

		switch (saView){
			case 'inline': {
				const autoHeight = element.dataset.saAutoHeight ? !!parseInt(element.dataset.saAutoHeight, 10) : undefined

				instance.initInline({element, autoHeight })
				break
			}
			case 'full': {
				instance.initFull(element)
				break;
			}
			case 'popup': {
				instance.initPopup({element, size: element.dataset.saSize})
				break;
			}
			case 'slider': {
				instance.initSlider({ element, size: element.dataset.saSize, position:
					element.dataset.saPosition })
				break;
			}
			case 'chat': {
				instance.initChat({buttonBgColor: element.dataset.saButtonBgColor,
					buttonColor: element.dataset.saButtonColor,
					icon: element.dataset.saIcon,
					isAutoOpen: !!parseInt(element.dataset.saAutoOpen),
					text: element.dataset.saButtonText,
					fontSize:element.dataset.saFontSize   })
				break;
			}
			default:
				break;
		}
	}

	window.ScoreAppWidget = ScoreAppWidget
	window.addEventListener("message", (e) => {
	    const { SASID, event, data } = e.data;

	    if (SASID) {
	        localStorage.setItem('SASID', SASID);
	    }

	    if (event && event.startsWith('scoreapp_')) {
			SATracking.track(event, data);
	    }
	}, false);

})();

(function (){
	const elements = document.querySelectorAll('[data-sa-url]')
	if (elements){
		elements.forEach((element)=>{
			ScoreAppWidget.createFromElement(element)
		})
	}
})();

