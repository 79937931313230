import { SATrackingEvents } from '../../constants/tracking'

class SA_GTM {
	static trackEvent(event, data) {

		switch (event) {
			case SATrackingEvents.LEAD_FORM_MODAL_OPENED:
			case SATrackingEvents.LEAD:
			case SATrackingEvents.QUESTIONS_STARTED:
			case SATrackingEvents.QUESTIONS_FINISHED:
			case SATrackingEvents.LEAD_DETAILS_UPDATED:
			case SATrackingEvents.QUESTION_ANSWERED:
				this.track(event, data)
				break
			default:
				break
		}
	}

	static track(event, data) {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event,
			...data,
		})
	}
}

export default SA_GTM
